import React, { useContext } from "react";
import * as styles from "./footer.module.scss";
import { useSiteQuery, useLink } from "../../../hooks";
import { GatsbyImage } from "gatsby-plugin-image";
import { Link } from "gatsby";

import Rectangle from '../../../images/Rectangle2x.png';
import FooterRight from '../../../images/footer-right-new.webp';
import darkthemefooterRight from '../../../images/dark-theme-footer-right.png'
import LinkdIN from '../../../images/linkedin.png';
import DarkLinkdIN from '../../../images/darklinkdin.png';
import Instagram from '../../../images/Instagram.png';
import DarkInstagram from '../../../images/darkinstagram.png';

import FooterGradient from '../../../images/gradientImages/footer-gradient.png'
import DarkGradient from "../../../images/gradientImages/footer-dark.png"
import { LayoutContext } from "../../../context/context";




const Footer = () => {
    const { footerLinks, footerLogo, darkThemeFooterLogo, privacyLink, termsLink } = useSiteQuery();
    const { isDarkMode } = useContext(LayoutContext) as { isDarkMode: boolean };

    return (
        <footer className={`container container-fs ${styles.mainContainer}`} >
            {/* <div className={styles.backgroundImage}>
            </div> */}
            <div className={styles.topImage}>
            </div>
            <div className={` ${styles.footerContainer}   dark-color `}>
                <img src={Rectangle} className={`gradient ${styles.footerBar}`} alt="Top Image" />
                <img
                    src={isDarkMode ? DarkGradient : FooterGradient}
                    className={`gradient ${styles.footerGradient}`}
                />
                <div className={styles.container}>


                    <div className={styles.col_1}>
                        <Link to={`/${useLink("/")}`}>
                            {isDarkMode ? (
                                <GatsbyImage
                                    image={darkThemeFooterLogo.gatsbyImageData}
                                    alt={darkThemeFooterLogo.title}
                                    objectFit="contain"
                                    className={styles.footerLogo}
                                />
                            ) :
                                <GatsbyImage
                                    image={footerLogo.gatsbyImageData}
                                    alt={footerLogo.title}
                                    objectFit="contain"
                                    className={styles.footerLogo}
                                />
                            }
                        </Link>
                    </div>
                    <div className={styles.col_3}>
                        <ul className={styles.footerLinks}>
                            {footerLinks.map((item, index) => {
                                return (
                                    <li key={index} className={`list-item ${styles.footerLinkSingle}`}>
                                        <a href={`/${useLink(item.slug)}`} target={`${item.slug.includes('digit') ? "_blank" : 'self'}`}>{item.title}</a>
                                    </li>
                                )


                            })}
                        </ul>
                    </div>
                    <div className={styles.col_2}>
                        <div className={styles.socialContainer}>
                            <p>Follow Us</p>
                            <div className={styles.socialIcons}>
                                <a href="https://www.linkedin.com/company/digitupindia?trk=blended-typeahead" target="_blank" rel="noopener noreferrer">
                                    {/* <img src={LinkdIN} alt="LinkdIN" /> */}
                                    <img src={isDarkMode ? DarkLinkdIN : LinkdIN} alt="LinkdIN" />
                                </a>
                                <a href="https://www.instagram.com/digitupindia/" target="_blank" rel="noopener noreferrer">
                                    {/* <img src={Instagram} alt="Instagram" /> */}
                                    <img src={isDarkMode ? DarkInstagram : Instagram} alt="Instagram" />

                                </a>
                            </div>
                        </div>

                        <div>
                            <Link to={`/${useLink("/")}`} className={`${styles.digitupLogoContainer}`}>
                                <p>Powered by</p>
                                {isDarkMode ? (
                                    <img src={darkthemefooterRight} alt="FooterRight logo" className={`${styles.digitupLogo}`} />
                                ) :
                                    <img src={FooterRight} alt="FooterRight logo" className={`${styles.digitupLogo}`} />
                                }
                            </Link>
                        </div>
                    </div>
                </div>
                <div className={`${styles.copyright} dark-theme-white-font `}>
                    <div className={styles.rights} >
                        <p className={styles.paraDesktop}>Copyright © 2024 Digitup Central | All Rights Reserved</p>

                    </div>
                    <div className={`list-item ${styles.policy}`}>
                        <span className={styles.privacy}><Link to={`/${useLink(privacyLink.slug)}`}>{privacyLink.title}</Link> </span>  |  <span className={styles.terms}><Link to={`/${useLink(termsLink.slug)}`}>{termsLink.title}</Link>  </span>
                    </div>
                </div>

                <div className={`styles.emptyContainer`}></div>
            </div>
        </footer>
    );
};

export default Footer;
